import * as React from "react";
import { useEffect } from "react";
import { Script } from "gatsby";
import Footer from "./Footer";
import PreLoader from "./PreLoader";
import Switcher from "./Switcher";
import Navbar from "./Navbar";
import Cookies from "./Cookies";
import { TINY_SLIDER_KEY } from "@/lib/utils/constants";
import { getMenuState, toggleMenu } from "@/lib/utils/utils";
import ScrollToTopButton from "@/components/ScrollToTopButton";

declare var __PATH_PREFIX__: string;

export type LayoutProps = {
  location: Location;
  title?: string;
};

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  children,
  location,
}) => {
  useEffect(() => {
    const { open } = getMenuState();
    if (!open) return;
    setTimeout(toggleMenu, 100);
  }, [location]);
  return (
    <>
      <PreLoader />
      <Switcher />
      <Navbar />
      {children}
      <Footer />
      <Cookies />
      <ScrollToTopButton />
      <Script
        src="/js/tiny-slider/min/tiny-slider.js"
        onLoad={() => {
          console.log("Tiny Slider loaded");
          localStorage.setItem(TINY_SLIDER_KEY, "loaded");
        }}
      />
    </>
  );
};

export default Layout;
