import React, { useEffect } from "react"
import {initCookies} from "@/lib/utils/utils"

const Cookies = () => {
  useEffect(() => {
    initCookies()
  }, [])
  return (
    <div className="cookie-popup fixed max-w-lg bottom-3 right-3 left-3 sm:left-0 sm:right-0 mx-auto bg-white dark:bg-secondary shadow dark:shadow-gray-800 rounded-md py-5 px-6 z-10">
      <p className="text-slate-400">
        This website uses cookies to provide you with a great user experience.
        By using it, you accept our{" "}
        <a
          href="/privacy-policy"
          className="text-emerald-600 dark:text-emerald-500 font-semibold"
        >
          use of cookies
        </a>
      </p>
      <div className="cookie-popup-actions text-right">
        <button className="absolute border-none bg-none p-0 cursor-pointer font-semibold top-2 right-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            className="w-6 h-6 text-dark dark:text-slate-200 text-2xl"
          >
            <path
              fill="currentColor"
              d="m13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  )
}
export default Cookies
