declare var tns: (settings: any) => any;
declare var Shuffle: any;

export function getCookieByName(name: string) {
  let c = document.cookie
    .split("; ")
    .find((cookie) => cookie && cookie.startsWith(name + "="));
  return c ? c.split("=")[1] : false;
}

export function changeTheme(e: Event) {
  e.preventDefault();
  const htmlTag = document.getElementsByTagName("html")[0];

  if (htmlTag.className.includes("dark")) {
    htmlTag.className = "light";
    document.cookie = "theme=light";
  } else {
    htmlTag.className = "dark";
    document.cookie = "theme=dark";
  }
}

export function topFunction() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

export function verifyScrollToShowButton() {
  const scrollTopButton = document.getElementById("back-to-top");
  if (scrollTopButton != null) {
    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      scrollTopButton.classList.add("flex");
      scrollTopButton.classList.remove("hidden");
    } else {
      scrollTopButton.classList.add("hidden");
      scrollTopButton.classList.remove("flex");
    }
  }
}

//Menu
/*********************/
/* Toggle Menu */

/*********************/
export function toggleMenu() {
  const { menuButton, open } = getMenuState();
  if (!menuButton) return;
  if (open) {
    menuButton.classList.remove("open");
  } else {
    menuButton.classList.add("open");
  }
  const menu = document.getElementById("navigation") as HTMLButtonElement;
  if (!menu) return;
  if (menu.style.display === "block") {
    menu.style.display = "none";
  } else {
    menu.style.display = "block";
  }
}

export function getMenuState(): {
  open: boolean;
  menuButton: HTMLButtonElement | null;
} {
  const menuButton = document.getElementById("isToggle") as HTMLButtonElement;
  if (!menuButton) {
    return {
      open: false,
      menuButton: null,
    };
  }
  return {
    open: menuButton.classList.contains("open"),
    menuButton,
  };
}

/*********************/
/*    Menu Active    */

/*********************/
export function getClosest(elem: any, selector: any) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches = function (s) {
      const matches = window.document.querySelectorAll(s);
      let i = matches.length;
      while (--i >= 0 && matches.item(i) !== this) {}
      return i > -1;
    };
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
}

export function activateMenu() {
  const menuItems =
    (document.getElementsByClassName(
      "sub-menu-item"
    ) as unknown as HTMLAnchorElement[]) || [];
  if (menuItems) {
    let matchingMenuItem = null;
    for (let idx = 0; idx < menuItems.length; idx++) {
      const menuItem = menuItems[idx];
      if (
        menuItem.href === window.location.href ||
        window.location.pathname.startsWith(
          menuItem.getAttribute("data-href") || ""
        )
      ) {
        matchingMenuItem = menuItem;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      let immediateParent = getClosest(matchingMenuItem, "li");
      if (immediateParent) {
        immediateParent.classList.add("active");
      }

      let parent = getClosest(matchingMenuItem, ".parent-menu-item");
      if (parent) {
        parent.classList.add("active");
        let parentMenuitem = parent.querySelector(".menu-item");
        if (parentMenuitem) {
          parentMenuitem.classList.add("active");
        }
        let parentOfParent = getClosest(parent, ".parent-parent-menu-item");
        if (parentOfParent) {
          parentOfParent.classList.add("active");
        }
      } else {
        let parentOfParent = getClosest(
          matchingMenuItem,
          ".parent-parent-menu-item"
        );
        if (parentOfParent) {
          parentOfParent.classList.add("active");
        }
      }
    }
  }
}

export function initTinySlider() {
  const controlsText = [
    '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"><path fill="currentColor" d="M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6l6 6z"/></svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"><path fill="currentColor" d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6z"/></svg>',
  ];
  if (typeof tns === "undefined") return;
  if (document.getElementsByClassName("tiny-single-item").length > 0) {
    tns({
      container: ".tiny-single-item",
      items: 1,
      controls: false,
      mouseDrag: true,
      loop: true,
      rewind: true,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 3000,
      navPosition: "bottom",
      speed: 400,
      gutter: 16,
    });
  }

  if (document.getElementsByClassName("tiny-one-item").length > 0) {
    tns({
      container: ".tiny-one-item",
      items: 1,
      controls: true,
      mouseDrag: true,
      loop: true,
      rewind: true,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 3000,
      navPosition: "bottom",
      controlsText,
      nav: false,
      speed: 400,
      gutter: 0,
    });
  }

  if (document.getElementsByClassName("tiny-two-item").length > 0) {
    tns({
      container: ".tiny-two-item",
      controls: true,
      mouseDrag: true,
      loop: true,
      rewind: true,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 3000,
      navPosition: "bottom",
      controlsText,
      nav: false,
      speed: 400,
      gutter: 0,
      responsive: {
        768: {
          items: 2,
        },
      },
    });
  }

  if (document.getElementsByClassName("tiny-three-item").length > 0) {
    tns({
      container: ".tiny-three-item",
      controls: false,
      mouseDrag: true,
      loop: true,
      rewind: true,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 3000,
      navPosition: "bottom",
      speed: 400,
      gutter: 12,
      responsive: {
        992: {
          items: 3,
        },

        767: {
          items: 2,
        },

        320: {
          items: 1,
        },
      },
    });
  }

  if (document.getElementsByClassName("tiny-six-item").length > 0) {
    tns({
      container: ".tiny-six-item",
      controls: true,
      mouseDrag: true,
      loop: true,
      rewind: true,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 3000,
      navPosition: "bottom",
      controlsText,
      nav: false,
      speed: 400,
      gutter: 0,
      responsive: {
        1025: {
          items: 6,
        },

        992: {
          items: 4,
        },

        767: {
          items: 3,
        },

        320: {
          items: 1,
        },
      },
    });
  }

  if (document.getElementsByClassName("tiny-twelve-item").length > 0) {
    tns({
      container: ".tiny-twelve-item",
      controls: true,
      mouseDrag: true,
      loop: true,
      rewind: true,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 3000,
      navPosition: "bottom",
      controlsText,
      nav: false,
      speed: 400,
      gutter: 0,
      responsive: {
        1025: {
          items: 12,
        },

        992: {
          items: 8,
        },

        767: {
          items: 6,
        },

        320: {
          items: 2,
        },
      },
    });
  }

  if (document.getElementsByClassName("tiny-five-item").length > 0) {
    tns({
      container: ".tiny-five-item",
      controls: true,
      mouseDrag: true,
      loop: true,
      rewind: true,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 3000,
      navPosition: "bottom",
      controlsText,
      nav: false,
      speed: 400,
      gutter: 0,
      responsive: {
        1025: {
          items: 5,
        },

        992: {
          items: 4,
        },

        767: {
          items: 3,
        },

        425: {
          items: 1,
        },
      },
    });
  }

  if (document.getElementsByClassName("tiny-home-slide-four").length > 0) {
    tns({
      container: ".tiny-home-slide-four",
      controls: true,
      mouseDrag: true,
      loop: true,
      rewind: true,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 3000,
      navPosition: "bottom",
      controlsText,
      nav: false,
      speed: 400,
      gutter: 0,
      responsive: {
        1025: {
          items: 4,
        },

        992: {
          items: 3,
        },

        767: {
          items: 2,
        },

        320: {
          items: 1,
        },
      },
    });
  }
}

export class ShuffleSetup {
  private shuffle: any;
  private _activeFilters: any[] = [];

  constructor(private element: HTMLElement) {
    if (element) {
      this.shuffle = new Shuffle(element, {
        itemSelector: ".picture-item",
        sizer: element.querySelector(".my-sizer-element"),
      });

      // Log events.
      this.addShuffleEventListeners();
      this.addFilterButtons();
    }
  }

  /**
   * Shuffle uses the CustomEvent constructor to dispatch events. You can listen
   * for them like you normally would (with jQuery for example).
   */
  addShuffleEventListeners() {
    this.shuffle.on(Shuffle.EventType.LAYOUT, (data: any) => {
      console.log("layout. data:", data);
    });
    this.shuffle.on(Shuffle.EventType.REMOVED, (data: any) => {
      console.log("removed. data:", data);
    });
  }

  addFilterButtons() {
    const options = document.querySelector(".filter-options");
    if (!options) {
      return;
    }

    const filterButtons = Array.from(options.children);
    const onClick = this.handleFilterClick.bind(this);
    filterButtons.forEach((button) => {
      button.addEventListener("click", onClick, false);
    });
  }

  _removeActiveClassFromChildren(parent: HTMLElement) {
    const { children } = parent;
    for (let i = children.length - 1; i >= 0; i--) {
      children[i].classList.remove("active");
    }
  }

  private handleFilterClick(evt: any) {
    const btn = evt.currentTarget;
    const isActive = btn.classList.contains("active");
    const btnGroup = btn.getAttribute("data-group");

    this._removeActiveClassFromChildren(btn.parentNode);

    let filterGroup;
    if (isActive) {
      btn.classList.remove("active");
      filterGroup = Shuffle.ALL_ITEMS;
    } else {
      btn.classList.add("active");
      filterGroup = btnGroup;
    }

    this.shuffle.filter(filterGroup);
  }
}

export function initProjectCounter() {
  try {
    const counter = document.querySelectorAll(
      ".counter-value"
    ) as unknown as HTMLDivElement[];
    const speed = 2500;

    counter.forEach((counter_value, i) => {
      const updateCount = () => {
        const target = Number(counter_value.getAttribute("data-target"));
        const count = Number(counter_value.innerText);

        // Lower inc to slow and higher to slow
        var inc = target / speed;

        if (inc < 1) {
          inc = 1;
        }

        // Check if target is reached
        if (count < target) {
          // Add inc to count and output in counter_value
          counter_value.innerText = (count + inc).toFixed(0);
          // Call function every ms
          setTimeout(updateCount, i === 0 ? 250 : 50);
        } else {
          counter_value.innerText = target.toString();
        }
      };
      updateCount();
    });
  } catch (err) {
    console.log("Error on init component", err);
  }
}

export function initCookies() {
  try {
    /* common fuctions */
    function el(selector: string) {
      return document.querySelector(selector) as HTMLElement;
    }

    function els(selector: string) {
      return document.querySelectorAll(selector);
    }

    function on(
      selector: string,
      event: string,
      action: EventListenerOrEventListenerObject
    ) {
      els(selector).forEach((e) => e.addEventListener(event, action));
    }

    /* popup button hanler */
    on(".cookie-popup button", "click", () => {
      el(".cookie-popup").classList.add("cookie-popup-accepted");
      document.cookie = `cookie-accepted=true`;
    });

    /* popup init hanler */
    if (getCookieByName("cookie-accepted") !== "true") {
      el(".cookie-popup").classList.add("cookie-popup-not-accepted");
    } else {
      el(".cookie-popup").classList.add("hidden");
    }
  } catch (error) {
    console.error("Error on apply cookie policies", error);
  }
}
