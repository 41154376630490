import React, { useEffect } from "react";
import { topFunction, verifyScrollToShowButton } from "@/lib/utils/utils";

const ScrollToTopButton = () => {
  useEffect(() => {
    window.addEventListener("scroll", verifyScrollToShowButton);
    return () => window.removeEventListener("scroll", verifyScrollToShowButton);
  }, []);

  return (
    <button
      type="button"
      onClick={topFunction}
      id="back-to-top"
      className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 right-5 h-9 w-9 text-center bg-primary/80 text-secondary/90 leading-9 justify-center items-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        className="w-6 h-6"
        fill="none"
      >
        <path
          fill="currentColor"
          d="m17.71 11.29l-5-5a1 1 0 0 0-.33-.21a1 1 0 0 0-.76 0a1 1 0 0 0-.33.21l-5 5a1 1 0 0 0 1.42 1.42L11 9.41V17a1 1 0 0 0 2 0V9.41l3.29 3.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42"
        ></path>
      </svg>
    </button>
  );
};
export default ScrollToTopButton;
