import React, { useEffect, useRef } from "react"
import { toggleMenu } from "@/lib/utils/utils"
import { Link } from "gatsby"

const Navbar = () => {
  const navRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function windowScroll() {
      if (!navRef.current) return
      const navbar = navRef.current as HTMLDivElement
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        navbar.classList.add("nav-sticky")
      } else {
        navbar.classList.remove("nav-sticky")
      }
    }

    function activateScroll(ev: Event) {
      ev.preventDefault()
      windowScroll()
    }

    window.addEventListener("scroll", activateScroll)

    return () => window.removeEventListener("scroll", activateScroll)
  }, [])
  return (
    <nav ref={navRef} id="topnav" className="defaultscroll is-sticky">
      <div className="container">
        <Link className="logo pl-0" to="/">
          <span className="inline-block dark:hidden">
            <img
              src="/images/marca-tipo-rgb.png"
              className="l-dark w-auto h-10"
              alt=""
            />
            <img
              src="/images/marca-tipo-rgb.png"
              className="l-light w-auto h-10"
              alt=""
            />
          </span>
          <img
            src="/images/marca-tipo-rgb.png"
            className="hidden dark:inline-block w-auto h-10"
            alt=""
          />
        </Link>
        <div className="menu-extras">
          <div className="menu-item">
            <button
              className="navbar-toggle"
              id="isToggle"
              onClick={toggleMenu}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
        <div id="navigation">
          <ul className="navigation-menu nav-light @@class">
            <li>
              <Link to="/" className="sub-menu-item">
                Home
              </Link>
            </li>
            <li>
              <Link to="/projects" className="sub-menu-item">
                Projects
              </Link>
            </li>
            <li>
              <Link to="/contacts" className="sub-menu-item">
                Contact
              </Link>
            </li>
            <li>
              <Link to="/about" className="sub-menu-item">
                About
              </Link>
            </li>
            <li>
              <Link to="/blog" className="sub-menu-item">
                Blog
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
export default Navbar
