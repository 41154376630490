// @ts-ignore
import React from "react"
// custom typefaces
import "@fontsource-variable/montserrat"
import "@fontsource/merriweather"
// TailwindCSS
import "./src/styles/global.css"
// Highlighting for code blocks
import "prismjs/themes/prism.css"
import { GatsbyBrowser } from "gatsby"
import Layout from "./src/components/Layout"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props: { location, data },
}) => {
  console.log("wrapPageElement ", data)
  // const { site } = data as { site: Settings }

  return (
    <>
      <Layout title="" location={location}>
        {element}
      </Layout>
    </>
  )
}
